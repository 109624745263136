import * as React from "react";
import { Grid, IconButton } from "@mui/material";
import Slide from "@mui/material/Slide";

import logoBilhete from "../../assets/imgs/logo.png";
import { Menu } from "@mui/icons-material";
import MenuList from "./components/MenuList";
import { useState } from "react";
import LogoSVG from "../Logo";

export default function HeaderMenu({ rifaColor = "#3f67f3" }) {
  const [openMenu, setOpenMenu] = useState(false);

  const onClose = () => {
    setOpenMenu(false);
  };

  const onOpen = () => {
    setOpenMenu(true);
  };

  return (
    <>
      {openMenu && (
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          className="menuContent"
        >
          <Grid item xs={12} sm={4} className="menuBilhete">
            <MenuList onClose={onClose} />
          </Grid>
        </Grid>
      )}

      <Grid item xs="12" className="headerRifa">
        <Grid container direction="row" justifyContent="center">
          <Grid item xs={12} sm={8} md={5}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              sx={{ pl: 2, pr: 2 }}
            >
              <Grid item xs="6">
                <a className="navbar-brand">
                  <LogoSVG
                    color={rifaColor || "#3f67f3"}
                    width={130}
                    height={40}
                  />
                </a>
              </Grid>
              <Grid item xs="6">
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  height={40}
                  alignItems="center"
                >
                  <Grid item>
                    <IconButton onClick={() => onOpen()}>
                      <Menu />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
