import React, { useState } from "react";
import { Grid, Box, Pagination, Chip, Divider, Tooltip } from "@mui/material";
import { Button } from "../Button";
import { Typography } from "../Typography";
import { Close, ConfirmationNumber } from "@mui/icons-material";
import { CardContent } from "../CardContent";
import { NumericFormat } from "react-number-format";

const NumberSelector = ({
  buyTicket,
  setBuyTicket,
  setModalsetBuyTicket,
  dataRifa,
  dataForm,
  maxNumber,
  onSelect,
  setModalCheckout,
  updateTicketsNumber,
  isDisabledButton,
  soldNumbers = [],
  buyType,
  rifaColor,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const itemsPerPage = 25;

  const generateSequence = (max) => {
    return Array.from({ length: max }, (_, i) => i + 1);
  };

  const handleNumberSelect = (number) => {
    if (soldNumbers.includes(number)) return;

    let updatedSelection;

    if (selectedNumbers.includes(number)) {
      updatedSelection = selectedNumbers.filter((n) => n !== number);
      updateTicketsNumber("REMOVE", 1, dataForm.amount);
    } else {
      updatedSelection = [...selectedNumbers, number];
      updateTicketsNumber("ADD", 1, dataForm.amount);
    }

    setSelectedNumbers(updatedSelection);
    if (onSelect) {
      onSelect(updatedSelection);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleRemoveSelected = (number) => {
    const updatedSelection = selectedNumbers.filter((n) => n !== number);
    setSelectedNumbers(updatedSelection);
    updateTicketsNumber("REMOVE", 1, dataForm.amount);

    if (onSelect) {
      onSelect(updatedSelection);
    }
  };

  const numbers = generateSequence(maxNumber);
  const totalPages = Math.ceil(numbers.length / itemsPerPage);
  const displayedNumbers = numbers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <CardContent>
      {buyTicket ? (
        <Grid
          container
          direction="row"
          spacing={2}
          className="fadeIn"
          alignContent="flex-start"
        >
          <Grid item xs={12}>
            <Grid
              container
              sx={{
                justifyContent: "flex-end",
                alignItems: "flex-end",
                marginTop: -4,
              }}
            >
              <Button
                rifaColor={rifaColor}
                variant="contained"
                onClick={() => setBuyTicket(false)}
              >
                <Close />
              </Button>
            </Grid>
          </Grid>

          {/* Lista de números selecionados com scroll horizontal */}
          {selectedNumbers.length > 0 && (
            <Grid
              container
              sx={{
                pl: 2.5,
                pr: 1,
              }}
              className="fadeIn"
            >
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  align="left"
                  sx={{ lineHeight: 1, mt: -1 }}
                >
                  Bilhetes selecionados:
                </Typography>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  overflowX: "auto",
                  gap: 1,
                  p: 1,
                  whiteSpace: "nowrap",
                  border: "2px dashed",
                  borderColor: "divider",
                  borderRadius: 4,
                  mt: 0.5,
                  mb: 2,
                }}
              >
                {selectedNumbers
                  .slice() // Faz uma cópia do array para evitar mutações diretas no estado
                  .sort((a, b) => a - b) // Ordena os números em ordem crescente
                  .map((number) => (
                    <Chip
                      sx={{
                        backgroundColor: rifaColor,
                        fontWeight: "bold",
                      }}
                      key={number}
                      label={number}
                      onDelete={() => handleRemoveSelected(number)}
                      color="primary"
                    />
                  ))}
              </Box>
            </Grid>
          )}

          <Box alignItems="flex-start" justifyContent="flex-start">
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  align="left"
                  sx={{ pl: 3, pr: 3, lineHeight: 1 }}
                >
                  Selecione um ou mais números de bilhetes:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={1}
                  justifyContent="space-between"
                  direction="row"
                  sx={{
                    pl: 2.7,
                    pr: 1,
                    overflowY: "auto",
                    maxHeight: buyType == 2 ? `calc(100vh - 380px)` : `auto`,
                  }}
                >
                  {displayedNumbers.map((number) => (
                    <Tooltip
                      title={soldNumbers.includes(number) ? `Vendido` : ``}
                      key={number}
                      disableInteractive
                    >
                      <Grid item>
                        <Button
                          rifaColor={rifaColor}
                          variant={
                            selectedNumbers.includes(number)
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() => handleNumberSelect(number)}
                          disabled={soldNumbers.includes(number)}
                          color={
                            soldNumbers.includes(number) ? "error" : "primary"
                          }
                        >
                          <NumericFormat
                            value={number}
                            decimalScale={2}
                            decimalSeparator=","
                            displayType="text"
                            thousandSeparator="."
                          />
                        </Button>
                      </Grid>
                    </Tooltip>
                  ))}
                </Grid>
              </Grid>
            </Grid>

            {totalPages > 1 && (
              <Box mt={2} display="flex" justifyContent="center">
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
                  variant="outlined"
                  size="small"
                />
              </Box>
            )}

            <Grid item xs={12} sx={{ mb: 1, mt: 2, pl: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  border: "2px dashed",
                  borderColor: "divider",
                  p: 1,
                  borderRadius: 1,
                }}
              >
                <Grid item xs={6} sx={{ pl: 1 }}>
                  <Typography sx={{ fontWeight: 900, fontSize: 18 }}>
                    Bilhete
                  </Typography>
                  <Grid container direction="row" sx={{ mb: -2 }}>
                    <Typography sx={{ fontWeight: 900, fontSize: 15, pt: 0 }}>
                      R$
                    </Typography>
                    <Typography sx={{ fontWeight: 900, fontSize: 35, mt: -1 }}>
                      <NumericFormat
                        value={dataForm?.unitValue}
                        decimalScale={2}
                        decimalSeparator=","
                        displayType="text"
                        fixedDecimalScale
                        thousandSeparator="."
                      />
                    </Typography>
                    <Typography sx={{ fontWeight: 900, fontSize: 16, pt: 1.2 }}>
                      /cada
                    </Typography>
                  </Grid>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item xs={6} sx={{ pl: 1, mb: -2 }}>
                  <Typography sx={{ fontWeight: 900, fontSize: 18 }}>
                    Total
                  </Typography>
                  <Typography sx={{ fontWeight: 900, fontSize: 35, mt: -1 }}>
                    <NumericFormat
                      value={dataForm?.value}
                      decimalScale={2}
                      decimalSeparator=","
                      displayType="text"
                      fixedDecimalScale
                      thousandSeparator="."
                    />
                  </Typography>
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={12} sx={{ pl: 2, pt: 2 }}>
              <Button
                rifaColor={rifaColor}
                variant="contained"
                fullWidth
                disabled={selectedNumbers?.length < 1}
                size="large"
                onClick={() => setModalCheckout(true)}
                startIcon={<ConfirmationNumber />}
              >
                <span className="MuiButton-label-primary">
                  {selectedNumbers?.length >= 2
                    ? "Comprar Bilhetes"
                    : "Comprar Bilhete"}
                </span>
              </Button>
            </Grid>
          </Box>
        </Grid>
      ) : (
        <Grid item xs={12} sx={{ paddingTop: 1 }}>
          {!dataRifa?.data?.ticket?.active && (
            <Grid
              sx={{ mt: -2 }}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Chip
                color="error"
                label="Ação desativada pelo organizador!"
                size="medium"
              />
            </Grid>
          )}
          <div className="shineBtn online2">
            <Button
              rifaColor={rifaColor}
              variant="contained"
              disabled={!dataRifa?.data?.ticket?.active}
              fullWidth
              size="large"
              onClick={() => setModalsetBuyTicket(true)}
              startIcon={<ConfirmationNumber />}
            >
              <span className="MuiButton-label-primary">
                Selecionar Bilhetes
              </span>
            </Button>
          </div>
        </Grid>
      )}
    </CardContent>
  );
};

export default NumberSelector;
