import { Opacity } from "@mui/icons-material";
import { Button as MuiButton } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CustomButton = styled(MuiButton)(
  ({ theme, rifaColor, variant }) => ({
    borderRadius: 100,
    fontFamily: "'Zain', sans-serif",
    paddingLeft: 0,
    paddingRight: 0,
    fontWeight: 800,
    transition: "background-color 0.3s ease, border-color 0.3s ease",

    // Se for "contained", aplica a cor de fundo
    ...(variant === "contained" && {
      backgroundColor: rifaColor || theme.palette.primary.main,
      color: theme.palette.common.white, // Garante contraste adequado
      "&:hover": {
        backgroundColor: rifaColor
          ? `${rifaColor}CC`
          : theme.palette.primary.dark, // Aplica um tom mais escuro no hover
      },
    }),

    // Se for "outlined", aplica a cor na borda e define o texto com a mesma cor
    ...(variant === "outlined" && {
      backgroundColor: "transparent",
      border: `1px solid ${rifaColor || theme.palette.primary.main}`,
      color: rifaColor || theme.palette.primary.main, // Mantém o texto na mesma cor da borda
      "&:hover": {
        borderColor: rifaColor ? `${rifaColor}CC` : theme.palette.primary.dark,
        backgroundColor: `${rifaColor}10`, // Aplica um fundo levemente colorido no hover
      },
    }),

    "& .MuiButton-label-primary": {
      paddingTop: "4px",
      fontSize: 17,
    },
    "& .MuiButton-label-small": {
      paddingTop: "2px",
      fontSize: 14,
      marginLeft: -3,
    },
  })
);

export const Button = ({ children, ...props }) => {
  return <CustomButton {...props}>{children}</CustomButton>;
};
