import React from "react";

import { Add, Remove, Close, ConfirmationNumber } from "@mui/icons-material";
import { NumericFormat } from "react-number-format"; // Supondo que este seja um componente externo
import { TextFieldAmount } from "../TextFieldAmount";
import { Box, CardContent, Chip, Divider, Grid } from "@mui/material";
import { Button } from "../Button";
import { Typography } from "../Typography";

const BuyTicketContent = ({
  buyTicket,
  setBuyTicket,
  updateTicketsNumber,
  handleAmountChange,
  dataForm,
  dataRifa,
  setModalCheckout,
  setModalsetBuyTicket,
  isDisabledButton,
  rifaColor,
}) => {
  return (
    <CardContent>
      {buyTicket ? (
        <>
          <Grid container direction="row" spacing={2} className="fadeIn">
            <Grid item xs={12}>
              <Grid
                container
                sx={{
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  marginTop: -4,
                }}
              >
                <Button
                  rifaColor={rifaColor}
                  variant="contained"
                  onClick={() => setBuyTicket(false)}
                >
                  <Close />
                </Button>
              </Grid>
            </Grid>
            {[1, 10, 100, 250].map((value) => (
              <Grid item xs={3} key={value}>
                <Button
                  fullWidth
                  rifaColor={rifaColor}
                  variant="outlined"
                  onClick={() =>
                    updateTicketsNumber("ADD", value, dataForm.amount)
                  }
                >
                  <span className="MuiButton-label-primary">+ {value}</span>
                </Button>
              </Grid>
            ))}
            <Grid item xs={12} className="numberButtonsHidden">
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <TextFieldAmount
                  id="input-with-sx"
                  onChange={handleAmountChange}
                  type="number"
                  fullWidth
                  label="Quantidade"
                  value={dataForm?.amount}
                  size="medium"
                  variant="outlined"
                  inputProps={{
                    style: { textAlign: "center", WebkitAppearance: "none" },
                  }}
                  InputProps={{
                    endAdornment: (
                      <Button
                        variant="contained"
                        rifaColor={rifaColor}
                        onClick={() =>
                          updateTicketsNumber("ADD", 1, dataForm.amount)
                        }
                      >
                        <Add
                          sx={{ color: "white", mr: 0.5, ml: 0.5, my: 0.5 }}
                        />
                      </Button>
                    ),
                    startAdornment: (
                      <Button
                        variant="contained"
                        rifaColor={rifaColor}
                        onClick={() =>
                          updateTicketsNumber("REMOVE", 1, dataForm.amount)
                        }
                      >
                        <Remove
                          sx={{ color: "white", mr: 0.5, ml: 0.5, my: 0.5 }}
                        />
                      </Button>
                    ),
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ mb: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  border: "2px dashed",
                  borderColor: "divider",
                  position: "relative",
                  p: 1,
                  borderRadius: 1,
                }}
              >
                <Grid item xs={6} sx={{ pl: 1 }}>
                  <Typography sx={{ fontWeight: 900, fontSize: 18 }}>
                    Bilhete
                  </Typography>
                  <Grid container direction="row">
                    <Typography sx={{ fontWeight: 900, fontSize: 15, pt: 1 }}>
                      R$
                    </Typography>
                    <Typography sx={{ fontWeight: 900, fontSize: 35 }}>
                      <NumericFormat
                        value={dataForm?.unitValue}
                        decimalScale={2}
                        decimalSeparator=","
                        displayType="text"
                        fixedDecimalScale
                        thousandSeparator="."
                      />
                    </Typography>
                    <Typography sx={{ fontWeight: 900, fontSize: 16, pt: 2.2 }}>
                      /cada
                    </Typography>
                  </Grid>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item xs={6} sx={{ pl: 1 }}>
                  <Typography sx={{ fontWeight: 900, fontSize: 18 }}>
                    Total
                  </Typography>
                  <Typography sx={{ fontWeight: 900, fontSize: 35 }}>
                    <NumericFormat
                      value={dataForm?.value}
                      decimalScale={2}
                      decimalSeparator=","
                      displayType="text"
                      fixedDecimalScale
                      thousandSeparator="."
                    />
                  </Typography>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Button
                rifaColor={rifaColor}
                variant="contained"
                fullWidth
                disabled={isDisabledButton}
                size="large"
                onClick={() => setModalCheckout(true)}
                startIcon={<ConfirmationNumber />}
              >
                <span className="MuiButton-label-primary">
                  {dataForm?.amount >= 2
                    ? "Comprar Bilhetes"
                    : "Comprar Bilhete"}
                </span>
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid item xs={12} sx={{ paddingTop: 1 }}>
          {!dataRifa?.data?.ticket?.active && (
            <Grid
              sx={{ mt: -2 }}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Chip
                color="error"
                label="Ação desativada pelo organizador!"
                size="medium"
              />
            </Grid>
          )}
          <div
            className="shineBtn online2"
            style={{
              background: rifaColor || "#3f67f3",
            }}
          >
            <Button
              rifaColor={rifaColor}
              variant="contained"
              disabled={!dataRifa?.data?.ticket?.active}
              fullWidth
              size="large"
              onClick={() => setModalsetBuyTicket(true)}
              startIcon={<ConfirmationNumber />}
            >
              <span className="MuiButton-label-primary">
                Selecionar Bilhetes
              </span>
            </Button>
          </div>
        </Grid>
      )}
    </CardContent>
  );
};

export default BuyTicketContent;
