import { createTheme, ThemeProvider } from "@mui/material/styles";
import * as React from "react";
import ReactDOM from "react-dom/client";
import { Toaster } from "react-hot-toast";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import "./index.css";
import Checkout from "./Pages/Checkout";
import Transactions from "./Pages/Transactions";
import ProvacyPolicy from "./ProvacyPolicy";
import reportWebVitals from "./reportWebVitals";
import Rifa from "./Rifa";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://c12c38f4dd0eda53bc311a95a3cabe46@o4508781991297024.ingest.us.sentry.io/4508782005059584",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/bilhete-app-api\.onrender\.com/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/rifa/:username/:uuid",
    element: <Rifa />,
  },
  {
    path: "/checkout/:uuid",
    element: <Checkout />,
  },
  {
    path: "/privacidade",
    element: <ProvacyPolicy />,
  },
  {
    path: "/transactions/:uuid",
    element: <Transactions />,
  },
]);

const theme = createTheme({
  palette: {
    primary: {
      main: "#3f67f3",
    },
    secondary: {
      main: "#3f67f3",
      light: "#F5EBFF",
      contrastText: "#FFFFFF",
    },
  },
});

const container = document.getElementById("app");
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
      <Toaster gutter={8} />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
